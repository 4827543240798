import { Grid } from "@mui/material";

import { useAppDispatch } from '../../hooks';
import { useAppContext } from '../../Context';

import { useEffect } from "react";
import { setLoading } from "../../slices/miscSlice";
import { setLines } from "../../slices/linesSlice";

import ViewLines from "./ViewLines";
import ViewCustomers from "./ViewCustomers";
import ViewGroupsOT from "./ViewGroupsOT";
import { setGroupsOT } from "../../slices/groupsOTSlice";

const ViewSettings: React.FC = () => {
    const  { apiCall} = useAppContext();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const getLines = async () => {
            dispatch(setLoading(true));
            const response = await apiCall('GET', 'lines/', null, '', 'Error al obtener las líneas');
            dispatch(setLoading(false));
            dispatch(setLines(response));
        }
        getLines();

        const getGroupsOT = async () =>{
            dispatch(setLoading(true));
            const response = await apiCall('GET', 'groups-ot/', null, '', 'Error al obtener los grupos de orden de trabajo');
            dispatch(setLoading(false));
            dispatch(setGroupsOT(response));
        }
        getGroupsOT();

        // eslint-disable-next-line
    }, []);

    return (
        <div>
        <h1>Configuraciones</h1>
            <div className="container-wrap">
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={6} className="text-left">
                      <ViewLines hideActions={false} />
                    </Grid>
                    <Grid item xs={12} sm={6} className="text-right">
                       <ViewGroupsOT />
                       <br/>
                       <ViewCustomers />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
    }

export default ViewSettings;

