import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
interface CustomerState {
    customers: Customer[];
    customer : Customer;
}

export interface Customer{
    id: number;
    siigo_id?: string;
    customer_name: string;
    customer_city?: number;
    customer_city_name?: string;
    customer_state_name?: string;
    customer_country_name?: string;
}

// Define the initial state using that type
const initialState: CustomerState = {
    customers : [],
    customer : {
        id: 0,
        siigo_id: "",
        customer_name: "",
        customer_city: 0,
        customer_city_name: "",
        customer_state_name: "",
        customer_country_name: "",
    }
}

export const customerSlice = createSlice({
    name: 'customer',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setCustomers: (state, action: PayloadAction<Customer[]>) => {
            state.customers = action.payload;
        },
        setCustomer: (state, action: PayloadAction<Customer>) => {
            state.customer = action.payload;
        }
    },
})

export const { setCustomers, setCustomer } = customerSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCustomer = (state: RootState) => state.customer
export const selectCustomers = (state: RootState) => state.customer.customers

export default customerSlice.reducer