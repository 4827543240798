import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useAppContext} from '../../Context';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { initialStateOrders, setConsecutive, setConsecutives } from '../../slices/ordersSlice';
import { setLines } from '../../slices/linesSlice';
import { ConsecutiveType, LineType } from '../../types';

import { usePermissions, printConsecutiveTable, useGroups } from '../../services';
import { setLoading, setPopAlert } from '../../slices/miscSlice';
import { compressImage } from '../../Utils';
import { LoaderTemporal } from '../../components/shared/Loader';
import Grid from '@mui/material/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { Button, Label } from '../../components/shared/FormElements';
import TextField from '@mui/material/TextField';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { Autocomplete } from '@mui/material';
import { constants } from '../../constants';

const LoadImage : React.FC<{order_id: number, consecutive_id: number, editMode:boolean}> = ({order_id, consecutive_id, editMode}) => {
    const {apiCall} = useAppContext();
    const [blob, setBlob] = useState<string>("Cargando...");
    const dispatch = useAppDispatch();
    const consecutive : ConsecutiveType = useAppSelector((state: any) => state.orders.consecutive);

    useEffect(() => {
        if (!order_id) return ;
        const getImage = async () => {
            const response = await apiCall('GET', `order/${order_id}/consecutive/${consecutive_id}/boceto/`, null, '', 'Error al obtener la imagen');
            if (response) {
                if (response.image) {
                    setBlob(response.image);
                    dispatch(setConsecutive({
                        ...consecutive,
                        image_cache: response.image
                    }));
                }else{
                    setBlob("No se ha agregado un boceto");
                }
            }
        }
        if (!consecutive.image_cache) getImage();
        else setBlob(consecutive.image_cache);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return blob !== "No se ha agregado un boceto" && blob !== "Cargando..." ? <> { editMode ? <><b>Actual:</b> <br/></>: null } <img src={blob} alt="boceto" className='width-90' /></>: <>{blob}</>;
}

const CreateEditConsecutive: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {apiCall} = useAppContext();
    const { id, consecutive_id } = useParams();

    const [viewMode, setViewMode] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);

    const { isAdministrador, isJefedeProduccion, isValidador } = useGroups(['Administrador', 'Jefe de Produccion', 'Validador']);
    
    const { canView_orders, canAdd_orders, canChange_orders } = usePermissions(['view_orders', 'add_orders', 'change_orders', 'delete_orders']);    

    const consecutives : Array<ConsecutiveType> = useAppSelector((state: any) => state.orders.consecutives);
    const consecutive : ConsecutiveType = useAppSelector((state: any) => state.orders.consecutive);
    const lineas : Array<LineType> = useAppSelector((state: any) => state.lines.lines);


    const [blobBoceto, setBlobBoceto] = useState<Blob | null>(null);

    const [obsequioGarantia , setObsequioGarantia] = useState<string>('');

    useEffect(() => {

        if (lineas?.length === 0) {
            const getLines = async () => {
                dispatch(setLoading(true));
                const response = await apiCall('GET', 'lines/', null, '', 'Error al obtener las líneas');
                dispatch(setLoading(false));
                dispatch(setLines(response));
            }
            getLines();
        }
       
        if (!consecutive_id && id) {
            dispatch(setConsecutive(initialStateOrders.consecutive));
            setViewMode(false);
            if (!canAdd_orders || !id) {
                navigate('/')
                return;
            }
            return;
        }
        
        if(!consecutive_id){
            setViewMode(false);
            setEditMode(false);
            dispatch(setConsecutive(initialStateOrders.consecutive));
            if (!canAdd_orders || !id) {
                navigate('/')
                return;
            }
            return;
        }

        if (id && consecutive_id && !canView_orders ) {
            navigate('/')
            return;
        }

        if (!id) {
            return;
        }

        setViewMode(true);


        
        if(!consecutive || consecutive.id === 0) {
            const getconsecutive = consecutives.find((order: any) => order.id === parseInt(id));
            if (getconsecutive) {
                if (getconsecutive?.gift) setObsequioGarantia('gift'); 
                if (getconsecutive?.warranty) setObsequioGarantia('warranty');
                dispatch(setConsecutive(getconsecutive));
            }else{
                getConsecutive();
            }
        }else{
            if (consecutive?.gift) setObsequioGarantia('gift') 
            if (consecutive?.warranty) setObsequioGarantia('warranty');
        }       

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getConsecutive = async () => {
        const response = await apiCall('GET', `order/${id}/consecutive/${consecutive_id}/`, null, '', 'Error al obtener el consecutivo');
        if (response?.gift) setObsequioGarantia('gift') 
        if (response?.warranty) setObsequioGarantia('warranty');
        dispatch(setConsecutive(response));
    }

    /*
     clothing_neck? : string;
    clothing_cut? : string;
    socks_quantity? : number;
    socks_color? : string;
    line? : LineType;

    */



    const handleSave = async() => {
        let body = new FormData();
        body.append('order', consecutive.order.id.toString()); 
        body.append('replica', consecutive.replica ? consecutive.replica : '');
        if (consecutive.clothing_quantity === undefined || consecutive.clothing_quantity === null) {
            dispatch(setPopAlert({message: 'La cantidad de prendas es requerida', show: true, alertType: 'error'}));
            return;
        }
        if (consecutive.clothing_quantity < 1) {
            dispatch(setPopAlert({message: 'La cantidad de prendas debe ser mayor a 0', show: true, alertType: 'error'}));
            return;
        }

        if (consecutive.socks_quantity === undefined || consecutive.socks_quantity === null) {
            dispatch(setPopAlert({message: 'La cantidad de medias es requerida', show: true, alertType: 'error'}));
            return;
        }

        if (consecutive.socks_quantity < 0) {
            dispatch(setPopAlert({message: 'La cantidad de medias debe ser mayor o igual a 0', show: true, alertType: 'error'}));
            return;
        }

        if (!consecutive.line) {
            dispatch(setPopAlert({message: 'La línea es requerida', show: true, alertType: 'error'}));
            return;
        }

        if (!consecutive.clothing_cut) {
            dispatch(setPopAlert({message: 'El corte de la prenda es requerido', show: true, alertType: 'error'}));
            return;
        }

        if (!consecutive.clothing_neck) {
            dispatch(setPopAlert({message: 'El cuello de la prenda es requerido', show: true, alertType: 'error'}));
            return;
        }

        body.append('clothing_quantity', consecutive.clothing_quantity.toString());
        body.append('line', consecutive.line.id.toString());
        body.append('clothing_cut', consecutive.clothing_cut);
        body.append('clothing_neck', consecutive.clothing_neck);
        body.append('socks_quantity', consecutive.socks_quantity.toString());
        body.append('socks_color', consecutive.socks_color ? consecutive.socks_color : '');


        if (obsequioGarantia && obsequioGarantia !== '') {
            body.append(obsequioGarantia, '1');
        }

        if (consecutive.image){
           body.append('image', blobBoceto ? blobBoceto : '');
        }
        const options = {
            isFormData : true
        };
        let response : any;
        if (!editMode) {
            response = await apiCall('POST', 'order/' + id + '/consecutive/', body, '', 'Error al guardar la orden', options);
        } else {
            response = await apiCall('PUT', `order/${id}/consecutive/${consecutive_id}/`, body, '', 'Error al guardar la orden', options);
        }
        
        if (response) {
            dispatch(setPopAlert({message: 'Orden guardada correctamente', show: true, alertType: 'success'}));
            const response = await apiCall('GET', 'order/' + id + '/consecutives/', null, '', 'Error al obtener las consecutivos');
            if (response) {
                dispatch(setConsecutives(response));
                navigate('/orden/' + id + '/consecutivos');
            }
        }
    }

    const obsequioGarantiaMap: Record<string, string> = {
        '': 'Ninguno',
        'gift': 'Obsequio',
        'warranty': 'Garantía'
    };

    const handleChange = (event: React.MouseEvent<HTMLElement>, newObsequioGarantia: string) => {
        setObsequioGarantia(newObsequioGarantia);
    }

    useEffect(() => {
        if (!consecutive?.image) return;
        const compress = async () => {
            dispatch(setLoading(true));
            const blob : Blob = await compressImage(consecutive.image) as Blob;
            setBlobBoceto(blob);
            dispatch(setLoading(false));
        }
        compress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consecutive?.image]);

    const handleEditar = () => {
        setViewMode(false);
        setEditMode(true);
    }

    const getBoceto = async () => {
        const resp = await apiCall('GET', `order/${id}/consecutive/${consecutive_id}/boceto/`, null, '', 'Error al obtener el boceto');
        if (resp?.image){
            return resp.image;
        }
        return null;
    }

    const handlePrintConsecutive = async () => {
        let boceto = null
        if (consecutive.image_cache){
            boceto = consecutive.image_cache;
        }else{
            boceto = await getBoceto();
        }
        dispatch(setLoading(true));
        await printConsecutiveTable(consecutive, boceto);
        dispatch(setLoading(false));
    }

    if (consecutive_id && consecutive?.id === 0 ) return <LoaderTemporal />

    const { order, 
        consecutive_number, 
        created_by, 
        replica, 
        gift, 
        warranty, 
        created_at, 
        updated_at, 
        clothing_quantity,  
        line,
        clothing_cut,
        clothing_neck,
        socks_quantity,
        socks_color
    } = consecutive ? consecutive : { 
        order: null, 
        consecutive_number: null, 
        created_by: null, 
        replica: null, 
        gift: null, 
        warranty: null, 
        created_at: null, 
        updated_at: null, 
        clothing_quantity: 0,
        line: null,
        clothing_cut: null,
        clothing_neck: null,
        socks_quantity: 0,
        socks_color: null
     };

    const isAbleToEdit = isAdministrador ? true : (canChange_orders && order?.is_deleted === false && (order?.status !== constants.orderStatus.EN_PROCESO  || order?.status === constants.orderStatus.FINALIZADO || order?.status === constants.orderStatus.BLOQUEADO) );


    return <div className="container-wrap">
    <Grid container spacing={2}>
        <Grid item xs={12} sm={9} className="text-left">
            <h2 className="text-left">{viewMode || editMode ? `Órden No. ${ id} - Consecutivo No. ${consecutive_number}` : "Nueva consecutivo para la orden No. " + id }</h2>
        </Grid> 
        <Grid item xs={12} sm={1} className="d-flex-center icon-btn">
            { viewMode ? <FontAwesomeIcon icon={faPrint} size="2x" className="text-primary" onClick={handlePrintConsecutive} /> : null}
        </Grid>
        <Grid item xs={12} sm={2} className="text-right">
            {canView_orders && <Button className="btn btn-primary" onClick={() => navigate('/orden/' + id + '/consecutivos')}>Regresar a consecutivos</Button>}
        </Grid>
    </Grid> 
    <div className="form-container">
        <Grid container spacing={4}>
            <Grid item xs={12} sm={6} className="text-left">
                {viewMode ?
                    <div className='form-group'>
                        <Label>Fecha de Órden</Label>
                        <div style={{display:"block"}} >{order?.order_date}</div>
                    </div>
                    :
                    null}

{viewMode ?
                    <div className='form-group'>
                        <Label>Fecha de Consecutivo</Label>
                        <div style={{display:"block"}} >{created_at? new Date(created_at).toLocaleDateString() : "No registra"}</div>
                    </div>
                    :
                    null}
                {viewMode ? <div className='form-group'>
                <Label>Cliente</Label>
                    
                        <> {order?.customer.customer_name} </>
                        
                </div>:null
                    }
                 <div className='form-group'>
                <Label>Línea</Label>
                    {viewMode ? 
                        <> {line?.line_name} </>
                        : 
                        <Autocomplete
                            options={lineas ? lineas : []}
                            getOptionLabel={(option : LineType) => option.line_name.toUpperCase()}
                            renderInput={(params) => <TextField className='form-control' {...params} label="Escribe el nombre de la línea..." />} 
                            onChange={(event: any, value: any) => {
                                dispatch(setConsecutive({
                                    ...consecutive,
                                    line: value
                                }));
                            }}
                            value={line}
                            renderOption={(props, option : LineType) => {
                                return (
                                <li {...props} key={option.id}>
                                    <span>
                                        {option.line_name}
                                    </span>
                                </li>
                                );
                            }
                            }    
                            />
                    }
                </div>
                <div className='form-group'>
                <Label>Corte de Prenda</Label>
                    {viewMode ? 
                        <> {clothing_cut ? clothing_cut : "N/A"} </>
                        : 
                        <TextField className='form-control' type="text" value={clothing_cut ? clothing_cut : ''}
                        onChange={(event) => {
                            dispatch(setConsecutive({
                                ...consecutive,
                                clothing_cut: event.target.value
                            }));
                        }} />
                    }
                </div>
                <div className='form-group'>
                <Label>Cuello de Prenda</Label>
                    {viewMode ? 
                        <> {clothing_neck ? clothing_neck : "N/A"} </>
                        : 
                        <TextField className='form-control' type="text" value={clothing_neck ? clothing_neck : ''}
                        onChange={(event) => {
                            dispatch(setConsecutive({
                                ...consecutive,
                                clothing_neck: event.target.value
                            }));
                        }} />
                    }
                </div>
                <div className='form-group'>
                <Label>Cantidad de Medias</Label>
                    {viewMode ? 
                        <> {socks_quantity ? socks_quantity : 0} </>
                        : 
                        <TextField className='form-control' type="number" value={socks_quantity ? socks_quantity : 0}
                        onChange={(event) => {
                            if (parseInt(event.target.value) < 0) {
                                return;
                            }
                            dispatch(setConsecutive({
                                ...consecutive,
                                socks_quantity: parseInt(event.target.value)
                            }));
                        }} 
                        onFocus={(event) => {
                            event.target.select();
                        }}
                        />
                    }
                </div>
                <div className='form-group'>
                <Label>Color de Medias</Label>
                    {viewMode ? 
                        <> {socks_color ? socks_color : "N/A"} </>
                        : 
                        <TextField className='form-control' type="text" value={socks_color ? socks_color : ''}
                        onChange={(event) => {
                            dispatch(setConsecutive({
                                ...consecutive,
                                socks_color: event.target.value
                            }));
                        }} />
                    }
                </div>
            </Grid>
            <Grid item xs={12} sm={6} className="text-left">
                <div className='form-group'>
                    <Label>Cantidad de prendas</Label>
                    {viewMode ? <div style={{display:"block"}} >{clothing_quantity}</div> : <TextField className='form-control' type="number" value={clothing_quantity}
                    onChange={(event) => {
                        if (parseInt(event.target.value) < 0) {
                            return;
                        }
                        dispatch(setConsecutive({
                            ...consecutive,
                            clothing_quantity: parseInt(event.target.value)
                        }));
                    }} 
                    onFocus={(event) => {
                        event.target.select();
                    }}
                    />}
                </div>
                <div className='form-group'>
                <Label>¿Réplica?</Label>
                    {viewMode ? 
                        <> {replica ? replica : "N/A"} </>
                        : 
                        <TextField className='form-control' type="text" value={replica ? replica : ''}
                        onChange={(event) => {
                            dispatch(setConsecutive({
                                ...consecutive,
                                replica: event.target.value
                            }));
                        }} />
                    }
                </div>
                <div className='form-group'>
                <Label>¿Es obsequio o garantía?</Label>
                    {viewMode ? 
                        <> {gift ? "Es un obsequio" : (warranty ? "Es una garantía" :"N/A" ) } </>
                        : <ToggleButtonGroup
                            color="primary"
                            value={obsequioGarantia}
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                            >
                            {obsequioGarantiaMap ? Object.keys(obsequioGarantiaMap).map((key) => {
                                return <ToggleButton value={key} key={key}>{obsequioGarantiaMap[key]}</ToggleButton>
                            }) : null}
                        </ToggleButtonGroup>
                    }
                </div>
                <div className='form-group'>
                    <Label>Boceto</Label>
                    {viewMode && order?.id ? 
                        <LoadImage order_id={order?.id} editMode={editMode} consecutive_id={consecutive.id}/>                    
                    :
                    <> 
                        {editMode && order?.id  ? 
                            <LoadImage order_id={order?.id} editMode={editMode} consecutive_id={consecutive.id}/>
                            : 
                            null
                        } 
                        <input type="file" className="form-control" name="image" id='image' onChange={(event) => {
                            dispatch(setConsecutive({
                                ...consecutive,
                                image: event.target.files ? event.target.files[0] : event.target.value
                            }));
                        }
                        }
                        accept = "image/png, image/jpeg"
                        />
                        </>
                    }
                    {blobBoceto && !viewMode ? <><br/><b>Nuevo:</b> <br/><img src={URL.createObjectURL(blobBoceto)} alt="boceto" className='width-90' /></> : null}
                    
                </div>
            </Grid>
        </Grid> 
    </div>
    { viewMode? <Grid container spacing={2}>
        <Grid item xs={12} sm={12} className="text-right">
           <span className="text-log"><b>Última Modificación:</b> {updated_at? new Date(updated_at).toLocaleString('es-CO') : null} - <b>Creado por:</b> {created_by?.name ? created_by.name : created_by?.username } </span>
        </Grid>
    </Grid>:null }

    <Grid container spacing={2}>
        <Grid item xs={12} sm={2} className="text-right">
            {!viewMode ? 
            <Button className="btn btn-primary" onClick={handleSave}>Guardar</Button>   
            : isAbleToEdit ? <Button className="btn btn-primary" onClick={handleEditar}>Editar</Button> : null}
        </Grid>
    </Grid> 
    <br/>
    </div>
}


export default CreateEditConsecutive;