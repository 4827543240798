import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../Context';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useSelector } from 'react-redux';
import { useGroups, usePermissions } from '../../services';
import { useEffect, useState } from 'react';

import { setTransaction } from '../../slices/transactionsSlice';
import { Autocomplete, Grid, MenuItem, Select, TextField } from '@mui/material';
import { Button, Label } from '../../components/shared/FormElements';
import { CustomerType, TransactionType, UserType, AssignmentType } from '../../types';
import { compressImage, formatAmount, formatNumber, parseNumber } from '../../Utils';
import { setLoading, setPopAlert } from '../../slices/miscSlice';
import { setCustomers } from '../../slices/customersSlice';
import { setUsers } from '../../slices/usersSlice';
import Assignments from './assignments/Assignments';
import { constants } from '../../constants';

const LoadImage: React.FC<{ transaction_id: number; editMode: boolean }> = ({ transaction_id, editMode }) => {
  const { apiCall } = useAppContext();
  const [blob, setBlob] = useState<string>('');
  const dispatch = useAppDispatch();
  const transaction: TransactionType = useAppSelector((state: any) => state.transactions.transaction);

  useEffect(() => {
    if (!transaction_id) return;
    const getImage = async () => {
      const response = await apiCall(
        'GET',
        `transactions/${transaction_id}/comprobante/`,
        null,
        '',
        'Error al obtener la imagen'
      );
      if (response) {
        if (response.image) {
          setBlob(response.image);
          dispatch(
            setTransaction({
              ...transaction,
              image_cache: response.image,
            })
          );
        } else {
          setBlob('No se ha agregado un comprobante');
        }
      }
    };
    if (!transaction.image_cache) getImage();
    else setBlob(transaction.image_cache);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return blob !== 'No se ha agregado un comprobante' && blob !== 'Cargando...' ? (
    <>
      {' '}
      {editMode ? (
        <>
          <b>Actual:</b> <br />
        </>
      ) : null}{' '}
      <img src={blob} alt='comprobante' className='width-90' />
    </>
  ) : (
    <>{blob}</>
  );
};

const CreateOrEditTransactions: React.FC = () => {
  const { apiCall } = useAppContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { code } = useParams();
  const transaction: TransactionType = useSelector((state: any) => state.transactions.transaction);
  const customers: Array<CustomerType> = useAppSelector((state: any) => state.customer.customers);
  const users = useAppSelector((state: any) => state.users.users);
  const [viewMode, setViewMode] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [currentTransaction, setCurrentTransaction] = useState<TransactionType>(transaction);
  const [blobComprobante, setBlobComprobante] = useState<Blob | null>(null);
  const { auth } = useAppContext();
  const { isVendedor } = useGroups(['Administrador', 'Vendedor']);
  const [assignments, setAssignments] = useState<AssignmentType[]>([]);
  const { canView_transactions, canChange_transactions, canAdd_transactions } = usePermissions([
    'view_transactions',
    'change_transactions',
    'add_transactions',
  ]);

  useEffect(() => {
    if (!currentTransaction?.image) return;
    const compress = async () => {
      dispatch(setLoading(true));
      const blob: Blob = (await compressImage(currentTransaction.image)) as Blob;
      setBlobComprobante(blob);
      dispatch(setLoading(false));
    };
    compress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTransaction?.image]);

  useEffect(
    () => {
      if (!canView_transactions) {
        navigate('/');
        return;
      }

      if (customers.length === 0) {
        const getCustomers = async () => {
          dispatch(setLoading(true));
          const response = await apiCall('GET', 'customers/', null, '', 'Error al obtener los clientes');
          dispatch(setLoading(false));
          dispatch(setCustomers(response));
        };
        getCustomers();
      }

      if (users?.length === 0 && !isVendedor) {
        const getUsers = async () => {
          dispatch(setLoading(true));
          const response = await apiCall('GET', 'users/group/Vendedor/', null, '', 'Error al obtener los usuarios');
          dispatch(setLoading(false));
          dispatch(setUsers(response));
        };
        getUsers();
      }

      if (code || code === undefined || code === '0') {
        setEditMode(true);
        return;
      }

      setViewMode(true);

      const getTransactions = async () => {
        if (parseInt(code) < 1) return;
        const response = await apiCall('GET', `transaction/${code}/`, null, '', 'Error al obtener el movimiento');
        if (response) {
          dispatch(setTransaction(response));
          setCurrentTransaction(response);
          getAssingmentsByTransactionCode();
        }
      };

      getTransactions();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getAssingmentsByTransactionCode = async () => {
    const response = await apiCall(
      'GET',
      `transactions/${code}/assignments/`,
      null,
      '',
      'Error al obtener las asignaciones'
    );
    if (response) {
      setAssignments(response);
    }
  };

  if (!canView_transactions) {
    navigate('/');
    return null;
  }

  const handleSave = async () => {
    if (!currentTransaction.customer) {
      dispatch(setPopAlert({ alertType: 'warning', message: 'Debes seleccionar un cliente', show: true }));
      return;
    }

    if (!currentTransaction.master_value) {
      dispatch(setPopAlert({ alertType: 'warning', message: 'Debes ingresar un valor master', show: true }));
      return;
    }

    if (!currentTransaction.income_date) {
      dispatch(setPopAlert({ alertType: 'warning', message: 'Debes seleccionar una fecha de ingreso', show: true }));
      return;
    }

    if (!currentTransaction.account) {
      dispatch(setPopAlert({ alertType: 'warning', message: 'Debes seleccionar un medio', show: true }));
      return;
    }

    if (!currentTransaction.reported_by && !isVendedor) {
      dispatch(setPopAlert({ alertType: 'warning', message: 'Debes seleccionar un vendedor', show: true }));
      return;
    }

    let body = new FormData();
    body.append('customer', currentTransaction?.customer?.id?.toString() ?? '');
    body.append('master_value', currentTransaction.master_value.toString());
    body.append('master_bank_code', currentTransaction?.master_bank_code ?? '');
    body.append('income_date', currentTransaction.income_date);
    body.append('account', currentTransaction.account);
    body.append('reported_by', currentTransaction?.reported_by?.id?.toString() ?? '');
    body.append('observation', currentTransaction.observation);
    if (blobComprobante) {
      body.append('image', blobComprobante ?? '');
    }

    const options = {
      isFormData: true,
    };

    if (transaction.code === 0) {
      if (!canAdd_transactions) return;
      const response = await apiCall(
        'POST',
        `transactions/`,
        body,
        'El movimiento se ha guardado exitosamente',
        'Error al guardar el movimiento',
        options
      );
      if (response) {
        dispatch(setTransaction(response));
        setCurrentTransaction(response);
        navigate(`/movimiento/${response.code}`);
        setViewMode(true);
      }
    } else {
      if (!canChange_transactions) return;
      const code_transaction = !code || code === '0' ? currentTransaction.code : code;
      const response = await apiCall(
        'PUT',
        `transaction/${code_transaction}/`,
        body,
        'El movimiento se ha actualizado exitosamente',
        'Error al actualizar el movimiento',
        options
      );
      if (response) {
        dispatch(setTransaction(response));
        setCurrentTransaction(response);
        setViewMode(true);
        setEditMode(false);
      }
    }
  };

  const handleEditar = () => {
    setViewMode(false);
    setEditMode(true);
  };

  const handleCustomerSelected = async (event: any, value: any) => {
    if (value) {
      setCurrentTransaction({
        ...currentTransaction,
        customer: {
          id: value.id,
          customer_name: value.customer_name,
          siigo_id: '',
          customer_city: {
            id: 0,
            city_name: '',
            state_name: '',
            country_name: '',
          },
        },
      });
    } else {
      // Opcionalmente, podrías restablecer customer a un valor inicial o mantener el estado anterior
      console.log('No se seleccionó ningún cliente');
    }
  };

  const handleValidateTransaction = async () => {
    const response = await apiCall(
      'PATCH',
      `transaction/${code}/validate/`,
      null,
      '',
      'Error al validar el movimiento'
    );
    if (response) {
      dispatch(setTransaction(response));
    }
  };

  const handleRejectTransaction = async () => {
    const response = await apiCall('PATCH', `transaction/${code}/reject/`, null, '', 'Error al rechazar el movimiento');
    if (response) {
      dispatch(setTransaction(response));
    }
  };

  return (
    <div className='container-wrap'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10} className='text-left'>
          <h2 className='text-left'>
            {viewMode || (editMode && transaction.code !== 0)
              ? `Movimiento No. ${transaction.code}`
              : 'Nuevo Movimiento'}
          </h2>
        </Grid>
        <Grid item xs={12} sm={2} className='text-right'>
          {canView_transactions && (
            <Button className='btn btn-primary' onClick={() => navigate(-1)}>
              Ir a Movimientos
            </Button>
          )}
        </Grid>
      </Grid>
      <div className='form-container'>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} className='text-left'>
            {viewMode ? (
              <div className='form-group'>
                <Label>Código</Label>
                <div style={{ display: 'block' }}>{transaction.code}</div>
              </div>
            ) : null}
            <div className='form-group'>
              <Label>Cliente</Label>
              {viewMode ? (
                <> {transaction?.customer?.customer_name} </>
              ) : (
                <Autocomplete
                  options={customers ? customers : []}
                  getOptionLabel={(option: CustomerType) => option.customer_name.toUpperCase()}
                  renderInput={(params) => (
                    <TextField className='form-control' {...params} label='Escribe el nombre del cliente...' />
                  )}
                  onChange={handleCustomerSelected}
                  value={currentTransaction ? currentTransaction.customer : null}
                  isOptionEqualToValue={(option, value) => option.id === value.id} // Comparar usando el ID
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      <span>{option.customer_name}</span>
                    </li>
                  )}
                />
              )}
            </div>
            <div className='form-group'>
              <Label>Estado</Label>
              {viewMode ? (
                <div style={{ display: 'block' }}>{transaction.status}</div>
              ) : (
                <Select
                  value={currentTransaction.status ?? 'No Validado'}
                  onChange={(e) => {
                    setCurrentTransaction({ ...currentTransaction, status: e.target.value });
                  }}
                  style={{ width: '100%' }}
                >
                  <MenuItem value='No Validado'>No Validado</MenuItem>
                  <MenuItem value='Validado'>Validado</MenuItem>
                  <MenuItem value='Rechazado'>Rechazado</MenuItem>
                </Select>
              )}
            </div>
            <div className='form-group'>
              <Label>Valor Master</Label>
              {viewMode ? (
                <>${transaction.master_value ? formatAmount(transaction.master_value) : '0,00'}</>
              ) : (
                <TextField
                  className='form-control'
                  type='text' // Cambia el tipo a 'text' para poder controlar el formato
                  value={formatNumber(currentTransaction?.master_value ?? 0)}
                  onChange={(event: { target: { value: any } }) => {
                    const rawValue = event.target.value;
                    const parsedValue = parseNumber(rawValue);

                    setCurrentTransaction({
                      ...currentTransaction,
                      master_value: isNaN(parsedValue) ? 0 : parsedValue,
                    });
                  }}
                  onFocus={(event: { target: { select: () => any } }) => event.target.select()}
                />
              )}
            </div>
            {transaction.code ? (
              <div className='form-group'>
                <Label>Valor Asignado</Label>
                <>${transaction.assigned_value ? formatAmount(transaction.assigned_value) : '0,00'}</>
              </div>
            ) : null}
            <div className='form-group'>
              <Label>Código de Banco</Label>
              {viewMode ? (
                <div style={{ display: 'block' }}>{transaction.master_bank_code}</div>
              ) : (
                <TextField
                  className='form-control'
                  type='text'
                  value={currentTransaction.master_bank_code}
                  onChange={(e: { target: { value: any } }) => {
                    setCurrentTransaction({ ...currentTransaction, master_bank_code: e.target.value });
                  }}
                />
              )}
            </div>
            <div className='form-group'>
              <Label>Fecha de Ingreso</Label>
              {viewMode ? (
                <div style={{ display: 'block' }}>{transaction.income_date}</div>
              ) : (
                <input
                  type='date'
                  className='form-control'
                  value={currentTransaction.income_date}
                  style={{ color: 'black', border: '1px solid #ced4da' }}
                  required={true}
                  onChange={(e) => {
                    setCurrentTransaction({ ...currentTransaction, income_date: e.target.value });
                  }}
                />
              )}
            </div>

            <div className='form-group'>
              <Label>Medio</Label>
              {viewMode ? (
                <div style={{ display: 'block' }}>{transaction.account}</div>
              ) : (
                <Select
                  value={currentTransaction.account ?? ''}
                  onChange={(e) => {
                    setCurrentTransaction({ ...currentTransaction, account: e.target.value });
                  }}
                  style={{ width: '100%' }}
                >
                  <MenuItem value=''>Selecciona</MenuItem>
                  <MenuItem value='Caja'>Caja</MenuItem>
                  <MenuItem value='Bancolombia'>Bancolombia</MenuItem>
                  <MenuItem value='Wompi'>Wompi</MenuItem>
                </Select>
              )}
            </div>
            {viewMode ? (
              <div className='form-group'>
                <Label>Reportado Por</Label>
                <div style={{ display: 'block' }}>
                  {transaction.reported_by?.first_name} {transaction.reported_by?.last_name}
                </div>
              </div>
            ) : (
              <>
                {isVendedor ? (
                  <input type='hidden' value={auth.user.id} />
                ) : (
                  <div className='form-group'>
                    <Label>Reportado Por</Label>
                    <Autocomplete
                      options={users}
                      getOptionLabel={(option: any) => option.username.toUpperCase()}
                      renderInput={(params) => (
                        <TextField className='form-control' {...params} label='Escribe el nombre del vendedor...' />
                      )}
                      onChange={(event: any, value: any) => {
                        setCurrentTransaction({
                          ...currentTransaction,
                          reported_by: value,
                        });
                      }}
                      value={currentTransaction ? currentTransaction.reported_by : null}
                      renderOption={(props, option: UserType) => {
                        return (
                          <li {...props} key={option.id}>
                            <span>
                              {option.first_name} {option.last_name}
                            </span>
                          </li>
                        );
                      }}
                    />
                  </div>
                )}
              </>
            )}
            <div className='form-group'>
              <Label>Observación</Label>
              {viewMode ? (
                <div style={{ display: 'block' }}>{transaction.observation}</div>
              ) : (
                <TextField
                  className='form-control'
                  type='text'
                  value={currentTransaction.observation}
                  onChange={(e: { target: { value: any } }) => {
                    setCurrentTransaction({ ...currentTransaction, observation: e.target.value });
                  }}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className='text-left'>
            <div className='form-group'>
              <Label>Comprobante {console.log(transaction)}</Label>
              {viewMode && transaction.code && transaction.code !== 0 ? (
                <LoadImage transaction_id={transaction.code} editMode={editMode} />
              ) : (
                <>
                  {editMode && transaction.code && transaction.code !== 0 ? (
                    <LoadImage transaction_id={transaction.code} editMode={editMode} />
                  ) : null}
                  <input
                    type='file'
                    className='form-control'
                    name='image'
                    id='image'
                    onChange={(event) => {
                      setCurrentTransaction({
                        ...currentTransaction,
                        image: event.target.files ? event.target.files[0] : event.target.value,
                      });
                    }}
                    accept='image/png, image/jpeg'
                  />
                </>
              )}
              {blobComprobante && !viewMode ? (
                <>
                  <br />
                  <b>Nuevo:</b> <br />
                  <img src={URL.createObjectURL(blobComprobante)} alt='comprobante' className='width-90' />
                </>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
      {viewMode ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} className='text-right'>
            <span className='text-log'>
              <b>Última Modificación:</b> {new Date(transaction.updated_at).toLocaleString('es-CO')} -{' '}
              <b>Fecha de Creación:</b> {new Date(transaction.created_at).toLocaleString('es-CO')}
            </span>
          </Grid>
        </Grid>
      ) : null}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} className='text-right'>
          {!viewMode ? (
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Button className='btn btn-primary' onClick={handleSave}>
                  Guardar
                </Button>
              </Grid>
              <Grid item sm={6}>
                <Button
                  className='btn btn-info'
                  onClick={() => {
                    setCurrentTransaction(transaction);
                    setViewMode(true);
                    setEditMode(false);
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          ) : canChange_transactions ? (
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Button className='btn btn-primary' onClick={handleEditar}>
                  Editar
                </Button>
              </Grid>
              {transaction?.status === constants.transactionStatus.NO_VALIDADO && (
                <Grid item sm={6}>
                  <Button className='btn btn-info' onClick={() => handleValidateTransaction()}>
                    Validar
                  </Button>
                </Grid>
              )}
              {transaction?.status === constants.transactionStatus.NO_VALIDADO && (
                <Grid item sm={6}>
                  <Button className='btn btn-info' onClick={() => handleRejectTransaction()}>
                    Rechazar
                  </Button>
                </Grid>
              )}
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      <br />

      {code && <Assignments assignments={assignments} getAssignments={getAssingmentsByTransactionCode} />}
    </div>
  );
};

export default CreateOrEditTransactions;
